import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'ID', sortable: true },
    { key: 'unit_number', sortable: true },
    { key: 'imei', sortable: true },
    { key: 'client_display_name', label: 'client name', sortable: true },
    { key: 'camera_type', sortable: true },
    { key: 'controller_bom', sortable: true },
    { key: 'battery', sortable: true },
    { key: 'signal', sortable: true },
    { key: 'firmware', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('camera_id')
  const isBusy = ref(false)
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)
  const cameraTypeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, cameraTypeFilter], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    console.log('fetchItems')
    if (ability.can('create', 'camerasmanagement')) {
      const qvars = {
        forceRefresh: store.state.camerasManagement.fetchingCamerasManagementList,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        cameraType: cameraTypeFilter.value,
      }
      console.log(qvars)
      store
        .dispatch('app-cameras/fetchCamerasManagement', qvars)
        .then(response => {
          const { cameras } = response.data
          totalItems.value = response.data.totalItems
          callback(cameras)
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching cameras list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveBatteryIcon = batteryLife => {
    // determine which battery icon to use
    if (batteryLife === 10) {
      // set icon
    }
    return ['fas', 'battery-full']
  }

  const resolveBatteryColor = batteryLife => {
    let myRet = { color: 'white' }
    if (batteryLife <= 10) {
      myRet = { color: 'red' }
    } else if (batteryLife > 10 && batteryLife <= 10.8) {
      myRet = { color: 'orange' }
    } else if (batteryLife > 10.8 && batteryLife <= 11.7) {
      myRet = { color: 'yellow' }
    } else if (batteryLife > 11.7) {
      myRet = { color: 'green' }
    }
    return myRet
  }

  const resolveBatteryVariant = batteryLife => {
    let myRet = batteryLife / 1
    myRet = myRet.toFixed(2)
    return `${myRet} v`
  }

  const resolveSignalIcon = batteryLife => {
    // determine which signal icon to use
    if (batteryLife === 10) {
      // set icon
    }
    return ['fas', 'signal']
  }

  const resolveSignalColor = antennaSignal => {
    let myRet = { color: 'white' }
    if (antennaSignal <= -20) {
      myRet = { color: 'red' }
    } else if (antennaSignal <= -18) {
      myRet = { color: 'orange' }
    } else if (antennaSignal <= -15) {
      myRet = { color: 'yellow' }
    } else {
      myRet = { color: 'green' }
    }
    return myRet
  }

  const resolveSignalVariant = antennaSignal => `${antennaSignal}dB`

  const resolveFirmwareVariant = firmwareVer => {
    const myRet = firmwareVer
    return `${myRet}`
  }

  const resolveStatusVariant = (status, online) => {
    if (online === false) return 'warning'
    if (status === 'pending') return 'warning'
    if (status === true) return 'success'
    if (status === false) return 'secondary'
    return 'success'
  }

  const resolveStatusVariantText = (status, online) => {
    if (online === false) return 'Offline'
    if (status === 'pending') return 'Warning'
    if (status === true) return 'Online'
    if (status === false) return 'Disabled'
    return 'Online'
  }

  return {
    fetchItems,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refListTable,

    resolveBatteryIcon,
    resolveBatteryColor,
    resolveBatteryVariant,
    resolveSignalIcon,
    resolveSignalColor,
    resolveSignalVariant,
    resolveFirmwareVariant,
    resolveStatusVariant,
    resolveStatusVariantText,
    refetchData,

    // Extra Filters
    statusFilter,
    cameraTypeFilter,
  }
}
