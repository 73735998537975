<template>

  <div
    v-if="$can('read', 'camerasmanagement')"
    class="mb-2"
  >

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Filters -->
      <cameras-management-list-filters
        :status-filter.sync="statusFilter"
        :status-options="statusOptions"
        :camera-type-filter.sync="cameraTypeFilter"
        :camera-type-options="cameraTypeOptions"
      />

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              class="mr-2"
              variant="primary"
              :disabled="this.$store.state.camerasManagement.fetchingCamerasManagementList"
              @click="refreshTheList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh Cameras List</span>
            </b-button>
          </b-col> -->
          <!-- Add Button -->
          <!-- <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="$can('create', 'users')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <feather-icon icon="UserPlusIcon" /><span class="text-nowrap ml-1">Add User</span>
              </b-button>
            </div>
          </b-col> -->
        </b-row>

        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              :disabled="this.$store.state.camerasManagement.fetchingCamerasManagementList"
              @click="refreshTheList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchItems"
        responsive
        :fields="tableColumns"
        primary-key="camer_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >
        <!-- Column: imei -->
        <template #cell(ID)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name: 'apps-cameras-management-edit', params: { cameraid: data.item.camera_id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.camera_id }}
            </b-link>
          </div>
        </template>
        <!-- Column: unit_number -->
        <template #cell(unit_number)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name: 'apps-cameras-management-edit', params: { cameraid: data.item.camera_id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.unit_number }}
            </b-link>
            <small class="text-muted">Phone: {{ data.item.phone }} on {{ data.item.cell_phone_carrier }}</small>
          </div>
        </template>
        <!-- Column: imei -->
        <template #cell(imei)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.imei }}</span>
          </div>
        </template>
        <!-- Column: Client Name -->
        <template #cell(client_display_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.client_display_name }}</span>
          </div>
        </template>
        <!-- Column: battery -->
        <template #cell(battery)="data">
          <div class="text-nowrap">
            <font-awesome-icon
              :icon="resolveBatteryIcon(data.item.current_status.battery)"
              size="lg"
              :style="resolveBatteryColor(data.item.current_status.battery)"
              class="mr-1"
            />
            <span class="align-text-top text-capitalize">{{ resolveBatteryVariant(data.item.current_status.battery) }}</span>
          </div>
        </template>
        <!-- Column: signal -->
        <template #cell(signal)="data">
          <div class="text-nowrap">
            <font-awesome-icon
              :icon="resolveSignalIcon(data.item.current_status.antenna_signal)"
              size="lg"
              :style="resolveSignalColor(data.item.current_status.antenna_signal)"
              class="mr-1"
            />
            <span class="align-text-top text-capitalize">{{ data.item.current_status.antenna_signal }}dB</span>
          </div>
        </template>
        <!-- Column: firmware -->
        <template #cell(firmware)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveFirmwareVariant(data.item.current_status.firmware.version) }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.is_active, data.item.is_online)}`"
            class="text-capitalize"
          >
            {{ resolveStatusVariantText(data.item.is_active, data.item.is_online) }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$can('update', 'camerasmanagement')"
              :to="{ name: 'apps-cameras-management-edit', params: { cameraid: data.item.camera_id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', 'camerasmanagement')"
              @click="deleteCamera(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import CamerasManagementListFilters from './CamerasManagementListFilters.vue'
import useCamerasManagmentList from './useCamerasManagementList'
import cameraStoreModule from '../cameraStoreModule'
import ability from '../../../../libs/acl/ability'

// import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    CamerasManagementListFilters,
    // UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  computed: {
    isFetchingUserList() {
      console.log('isFetchingUserList')
      console.log(this.$store.getters.getFetchingUserList)
      return this.$store.getters.getFetchingUserList
    },
  },
  methods: {
    refreshTheList() {
      console.log('refreshTheList')
      this.$store.state.camerasManagement.fetchingCamerasManagementList = true
      this.refetchData()
    },
    deleteCamera(item) {
      console.log('Delete Camera')
      const that = this
      if (ability.can('delete', 'cameras') || ability.can('delete', 'camerasmanagement')) {
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete camera ${item.unit_number}`, {
            title: 'Delete Camera',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const qvars = {
                camera_id: item.camera_id,
              }
              store
                .dispatch('app-cameras/deleteCamera', qvars)
                .then(response => {
                  console.log(response)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Camera deleted successfully.',
                      icon: 'CheckSquareIcon',
                      variant: 'success',
                    },
                  })
                  that.refreshUserList()
                })
                .catch(error => {
                  console.log(error)
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.message !== 'undefined') {
                    theErrorStr = error.response.data.message
                  } else {
                    theErrorStr += `${error}\n`
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error deleting Camera',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
      }
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'app-cameras'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, cameraStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Online', value: 'online' },
      { label: 'Offline', value: 'offline' },
      { label: 'Disabled', value: 'disabled' },
    ]

    const cameraTypeOptions = store.state.appSettings.camera_type_options

    const {
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      resolveBatteryIcon,
      resolveBatteryColor,
      resolveBatteryVariant,
      resolveSignalIcon,
      resolveSignalColor,
      resolveSignalVariant,
      resolveFirmwareVariant,
      resolveStatusVariant,
      resolveStatusVariantText,

      // Extra Filters
      statusFilter,
      cameraTypeFilter,
    } = useCamerasManagmentList()

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
      // Filter
      avatarText,
      // UI
      resolveBatteryIcon,
      resolveBatteryColor,
      resolveBatteryVariant,
      resolveSignalIcon,
      resolveSignalColor,
      resolveSignalVariant,
      resolveFirmwareVariant,
      resolveStatusVariant,
      resolveStatusVariantText,

      statusOptions,
      cameraTypeOptions,

      // Extra Filters
      statusFilter,
      cameraTypeFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
