import { API } from 'aws-amplify'
import store from '@/store'
import ability from '../../../libs/acl/ability'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCamerasManagement(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'camerasmanagement')) {
          console.log('fetchCamerasManagement')
          console.log(qvars)
          const { forceRefresh } = qvars
          store.state.camerasManagement.fetchingCamerasManagementList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()
          let totalItems = 0

          const resortItems = theItems => {
            console.log(qvars.sortBy)
            // sort the items
            let theSortBy = qvars.sortBy
            if (theSortBy === 'ID') {
              theSortBy = 'camera_id'
            }
            if (theSortBy === 'signal') {
              theSortBy = 'current_status.antenna_signal'
              if (qvars.sortDesc) {
                theItems.sort((a, b) => b.current_status.antenna_signal.localeCompare(a.current_status.antenna_signal))
              } else {
                theItems.sort((a, b) => a.current_status.antenna_signal.localeCompare(b.current_status.antenna_signal))
              }
            } else if (theSortBy === 'battery') {
              theSortBy = 'current_status.battery'
              if (qvars.sortDesc) {
                theItems.sort((a, b) => b.current_status.battery.localeCompare(a.current_status.battery))
              } else {
                theItems.sort((a, b) => a.current_status.battery.localeCompare(b.current_status.battery))
              }
            } else if (theSortBy === 'firmware') {
              theSortBy = 'current_status.firmware.version'
              if (qvars.sortDesc) {
                theItems.sort((a, b) => b.current_status.firmware.version.localeCompare(a.current_status.firmware.version))
              } else {
                theItems.sort((a, b) => a.current_status.firmware.version.localeCompare(b.current_status.firmware.version))
              }
            } else if (qvars.sortDesc) {
              theItems.sort((a, b) => b[theSortBy].localeCompare(a[theSortBy]))
            } else {
              theItems.sort((a, b) => a[theSortBy].localeCompare(b[theSortBy]))
            }
          }
          const paginateItems = theItems2 => {
            const start = (qvars.page - 1) * qvars.perPage
            const end = start + qvars.perPage
            return theItems2.slice(start, end)
          }
          const resortFilterItems = items => {
            const resultItems = []
            // first apply filters
            items.forEach(item => {
              let addThisItem = true
              if (qvars.cameraType !== null && item.camera_type !== qvars.cameraType) {
                addThisItem = false
              }
              if (qvars.status !== null && item.status !== qvars.status) {
                addThisItem = false
              }
              if (qvars.q !== null
                && !item.camera_id.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.unit_number.includes(qvars.q.toUpperCase())
                && !item.imei.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.clientname.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.camera_type.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.controller_bom.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.status.toUpperCase().includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              if (addThisItem) {
                resultItems.push(item)
              }
            })
            totalItems = resultItems.length
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          if (
            forceRefresh
            || localStorage.getItem('cameras_management') === null
            || parseInt(localStorage.getItem('cameras_management_lastRefresh'), 10) + 1440000 < timeDiff // cache for 24 hours
          ) {
            console.log('Refreshing Cameras')
            const apiName = 'APIGateway'
            const path = '/camera'
            const myInit = { response: true }

            API.get(apiName, path, myInit)
              .then(response => {
                console.log('fetchCamerasManagement Response')
                console.log(response)
                const theResponse = response
                const cameras = response.data
                cameras.forEach(element => {
                  if (element.is_online === false) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'offline'
                  } else if (element.is_active === true) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'online'
                  } else if (element.is_active === false) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'disabled'
                  }
                })
                console.log('Store Cameras in localstorage')
                console.log(JSON.stringify(cameras))
                localStorage.setItem('cameras_management', JSON.stringify(cameras))
                localStorage.setItem('cameras_management_lastRefresh', d1.getTime())
                theResponse.data.cameras = resortFilterItems(cameras)
                theResponse.data.totalItems = totalItems
                console.log(theResponse)
                store.state.camerasManagement.fetchingCamerasManagementList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.camerasManagement.fetchingCamerasManagementList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Camera Data')
            const theResponse = { data: {} }
            const cameraData = JSON.parse(localStorage.getItem('cameras_management'))
            theResponse.data.cameras = resortFilterItems(cameraData)
            theResponse.data.totalItems = totalItems
            console.log(theResponse)
            store.state.camerasManagement.fetchingCamerasManagementList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    fetchUser(ctx, username) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'users')) {
          const apiName = 'APIGateway'
          console.log(username)
          const path = `/user/${username}`
          // const path = '/user/'
          const myInit = { response: true }

          API.get(apiName, path, myInit)
            .then(response => {
              console.log(response)
              const theResponse = response
              const cameraData = {}
              cameraData.value = response.data
              // console.log(cameraData.value)
              // eslint-disable-next-line no-param-reassign
              cameraData.value.fullName = `${cameraData.value.first_name} ${cameraData.value.last_name}`
              // eslint-disable-next-line no-param-reassign
              cameraData.value.role = `${cameraData.value.cognito_group}`

              if (cameraData.value.is_active === true) {
                // eslint-disable-next-line no-param-reassign
                cameraData.value.status = 'active'
              } else if (cameraData.value.is_active === false) {
                // eslint-disable-next-line no-param-reassign
                cameraData.value.status = 'disabled'
              }
              console.log(cameraData.value)
              theResponse.data = cameraData.value
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    updateCamera(ctx, qvars) {
      return new Promise((resolve, reject) => {
        console.log('updateCamera')
        console.log(qvars)
        if (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement')) {
          const apiName = 'APIGateway'
          const path = '/camera'
          // seperate the original camera data our for later use
          const cameraData = qvars.orig_cameraData
          // remove any extra UI related properties before sending to backend
          const cameraDataClean = qvars
          const currentStatus = cameraDataClean.status
          if (qvars.is_active_new !== cameraDataClean.is_active) {
            cameraDataClean.is_active = qvars.is_active_new
          }
          delete cameraDataClean.is_active_new
          delete cameraDataClean.status
          delete cameraDataClean.orig_cameraData
          console.log('updateCamera')
          console.log(cameraDataClean)

          console.log(apiName)
          console.log(path)
          console.log(cameraData)
          console.log(currentStatus)

          API.patch(apiName, path, { body: cameraDataClean })
            .then(response => {
              console.log('updateCamera Response')
              console.log(response)
              // restore any extra UI related properties
              cameraDataClean.status = currentStatus
              try {
                // Update data in the local storage to avoid call to backend
                const oldCameras = JSON.parse(localStorage.getItem('cameras_management'))
                const newCameras = []
                oldCameras.forEach(item => {
                  if (item.camera_id === cameraData.camera_id) {
                    if (typeof cameraData.unit_id === 'undefined' || cameraData.unit_id === null || cameraData.unit_id === '' || !/^\d+$/.test(cameraData.unit_id) || cameraData.unit_id > 2999) {
                      cameraData.unit_number = `${cameraData.year_built}.${cameraData.camera_id}.${cameraData.controller_bom}`
                    } else {
                      cameraData.unit_number = `${cameraData.year_built}.${cameraData.unit_id}.${cameraData.controller_bom}`
                    }
                    newCameras.push(cameraData)
                  } else {
                    newCameras.push(item)
                  }
                })
                localStorage.setItem('cameras_management', JSON.stringify(newCameras))
              } catch (e) {
                console.log('')
              }
              try {
                // Update data in the local storage to avoid call to backend
                const oldCameras = JSON.parse(localStorage.getItem('cameras_console'))
                const newCameras = []
                oldCameras.forEach(item => {
                  if (item.camera_id === cameraData.camera_id) {
                    newCameras.push(cameraData)
                  } else {
                    newCameras.push(item)
                  }
                })
                localStorage.setItem('cameras_console', JSON.stringify(newCameras))
              } catch (e) {
                console.log('')
              }
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    deleteCamera(ctx, qvars) {
      return new Promise((resolve, reject) => {
        console.log('deleteCamera')
        console.log(qvars)
        if (ability.can('delete', 'cameras') || ability.can('delete', 'camerasmanagement')) {
          const apiName = 'APIGateway'
          const path = `/camera/${qvars.camera_id}`
          // make the call to the backend
          API.del(apiName, path)
            .then(response => {
              console.log('deleteCamera Response')
              console.log(response)
              try {
                // Update data in the local storage to avoid call to backend
                const oldCameras = JSON.parse(localStorage.getItem('cameras_management'))
                const newCameras = []
                oldCameras.forEach(item => {
                  if (item.camera_id !== qvars.camera_id) {
                    newCameras.push(item)
                  }
                })
                localStorage.setItem('cameras_management', JSON.stringify(newCameras))
              } catch (e) {
                console.log('')
              }
              try {
                // Update data in the local storage to avoid call to backend
                const oldCameras = JSON.parse(localStorage.getItem('cameras_console'))
                const newCameras = []
                oldCameras.forEach(item => {
                  if (item.camera_id === qvars.camera_id) {
                    newCameras.push(item)
                  }
                })
                localStorage.setItem('cameras_console', JSON.stringify(newCameras))
              } catch (e) {
                console.log('')
              }
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    fetchCameraLogs(ctx, qvars) {
      // fetches Commands Sent, camera and Alerts logs
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'camerasmanagement') || ability.can('read', 'cameras')) {
          console.log('fetchCommandsSentLog')
          // console.log(qvars)
          const { forceRefresh } = qvars
          store.state.logs.fetchingList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()

          let totalItems = 0

          const resortItems = theItems => {
            // sort the items
            let theSortBy = qvars.sortBy
            console.log(theSortBy)
            if (theSortBy === 'camera_date_time' || theSortBy === 'date_time_downloaded') {
              theSortBy = 'time_camera'
            }
            if (theSortBy === '') {
              theSortBy = 'time_camera'
              // eslint-disable-next-line no-param-reassign
              qvars.sortDesc = true
            }
            if (theSortBy === 'antenna_signal') {
              if (qvars.sortDesc) {
                theItems.sort((a, b) => b[theSortBy] - a[theSortBy])
              } else {
                theItems.sort((a, b) => a[theSortBy] - (b[theSortBy]))
              }
            } else {
              // eslint-disable-next-line no-lonely-if
              if (qvars.sortDesc) {
                theItems.sort((a, b) => b[theSortBy].localeCompare(a[theSortBy]))
              } else {
                theItems.sort((a, b) => a[theSortBy].localeCompare(b[theSortBy]))
              }
            }
          }
          const paginateItems = theItems2 => {
            const start = (qvars.page - 1) * qvars.perPage
            const end = start + qvars.perPage
            return theItems2.slice(start, end)
          }
          const resortFilterItems = items => {
            const resultItems = []
            // first apply filters
            items.forEach(item => {
              let addThisItem = true
              // console.log('qvars')
              // console.log(qvars)
              // console.log(item)
              // console.log(qvars.q)
              if (qvars.q !== null
                && !item.camera_id.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.clientname.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.sitename.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.time_camera.toUpperCase().includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              if (qvars.q !== null
                && ((item.input_name !== undefined && item.input_name.toUpperCase().includes(qvars.q.toUpperCase()))
                || (item.command !== undefined && item.command.toUpperCase().includes(qvars.q.toUpperCase()))
                || (item.type !== undefined && item.type.toUpperCase().includes(qvars.q.toUpperCase()))
                || (item.exception !== undefined && item.exception.toUpperCase().includes(qvars.q.toUpperCase())))) {
                addThisItem = true
              }
              if (addThisItem) {
                resultItems.push(item)
              }
            })
            totalItems = resultItems.length
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          if (
            forceRefresh
            || localStorage.getItem(`cameras_${qvars.media_type}_log_${qvars.camera_id}`) === null
            || parseInt(localStorage.getItem(`cameras_${qvars.media_type}_log_${qvars.camera_id}_lastRefresh`), 10) + 60000 < timeDiff // cache for 60 seconds
          ) {
            console.log('Refreshing Commands Log')
            const apiName = 'APIGateway'
            const path = `/camera/${qvars.camera_id}/log`
            const cameraQueryData = {
              clientname: qvars.clientname,
              media_type: qvars.media_type,
            }

            API.post(apiName, path, { body: cameraQueryData })
              .then(response => {
                const theResponse = response
                console.log(qvars.media_type)
                console.log(theResponse)
                theResponse.Items.forEach((element, index) => {
                  if (element.input_name !== undefined) {
                    // eslint-disable-next-line no-param-reassign
                    element.type = element.input_name
                  } else if (element.exception !== undefined) {
                    // eslint-disable-next-line no-param-reassign
                    element.type = 'exception'
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    element.type = 'unknown'
                  }
                  if (element.antenna_signal !== undefined) {
                    theResponse.Items[index].antenna_signal = parseFloat(element.antenna_signal.replace('dBm', ''))
                  }
                  theResponse.Items[index].time_camera = theResponse.Items[index].time_camera.replace('T', ' ').replaceAll('-', '/')
                })
                localStorage.setItem(`cameras_${qvars.media_type}_log_${qvars.camera_id}`, JSON.stringify(theResponse))
                localStorage.setItem(`cameras_${qvars.media_type}_log_${qvars.camera_id}_lastRefresh`, d1.getTime())
                store.state.logs.fetchingList = false
                theResponse.Items = resortFilterItems(theResponse.Items)
                theResponse.Count = totalItems
                resolve(theResponse)
              })
              .catch(error => {
                store.state.logs.fetchingList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Commands Log Data')
            const theData = JSON.parse(localStorage.getItem(`cameras_${qvars.media_type}_log_${qvars.camera_id}`))
            theData.Items = resortFilterItems(theData.Items)
            theData.Count = totalItems
            console.log(theData)
            store.state.logs.fetchingList = false
            resolve(theData)
          }
        } else {
          reject()
        }
      })
    },
    fetchCameraCommandsQued(ctx, qvars) {
      // fetches Commands Sent, camera and Alerts logs
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'camerasmanagement')) {
          console.log('fetchCameraCommandsQued')
          // console.log(qvars)
          const { forceRefresh } = qvars
          store.state.logs.fetchingList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()

          let totalItems = 0

          const resortItems = theItems => {
            // sort the items
            let theSortBy = qvars.sortBy
            console.log(theSortBy)
            if (theSortBy === 'camera_date_time' || theSortBy === 'date_time_downloaded') {
              theSortBy = 'que_dt'
            }
            if (theSortBy === '') {
              theSortBy = 'que_dt'
              // eslint-disable-next-line no-param-reassign
              qvars.sortDesc = true
            }
            // eslint-disable-next-line no-lonely-if
            if (qvars.sortDesc) {
              theItems.sort((a, b) => b[theSortBy].localeCompare(a[theSortBy]))
            } else {
              theItems.sort((a, b) => a[theSortBy].localeCompare(b[theSortBy]))
            }
          }
          const paginateItems = theItems2 => {
            const start = (qvars.page - 1) * qvars.perPage
            const end = start + qvars.perPage
            return theItems2.slice(start, end)
          }
          const resortFilterItems = items => {
            const resultItems = []
            // first apply filters
            items.forEach(item => {
              let addThisItem = true
              // console.log('qvars')
              // console.log(qvars)
              // console.log(item)
              // console.log(qvars.q)
              if (qvars.q !== null
                && !item.camera_id.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.que_dt.toUpperCase().includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              if (qvars.q !== null
                && ((item.command !== undefined && JSON.stringify(item.command).toUpperCase().includes(qvars.q.toUpperCase())))) {
                addThisItem = true
              }
              if (addThisItem) {
                resultItems.push(item)
              }
            })
            totalItems = resultItems.length
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          if (
            forceRefresh
            || localStorage.getItem(`cameras_${qvars.media_type}_log_${qvars.camera_id}`) === null
            || parseInt(localStorage.getItem(`cameras_${qvars.media_type}_log_${qvars.camera_id}_lastRefresh`), 10) + 60000 < timeDiff // cache for 60 seconds
          ) {
            console.log('Refreshing Qued Commands Log')
            const apiName = 'APIGateway'
            const path = `/camera/${qvars.camera_id}/que`

            API.get(apiName, path)
              .then(response => {
                const theResponse = {}
                theResponse.Items = response
                console.log(qvars.media_type)
                console.log(theResponse)
                if (theResponse.Items.length > 0) {
                  theResponse.Items.forEach((element, index) => {
                    if (element.input_name !== undefined) {
                      // eslint-disable-next-line no-param-reassign
                      element.type = element.input_name
                    } else if (element.exception !== undefined) {
                      // eslint-disable-next-line no-param-reassign
                      element.type = 'exception'
                    } else {
                      // eslint-disable-next-line no-param-reassign
                      element.type = 'unknown'
                    }
                    if (element.antenna_signal !== undefined) {
                      theResponse.Items[index].antenna_signal = parseFloat(element.antenna_signal.replace('dBm', ''))
                    }
                  })
                }
                localStorage.setItem(`cameras_${qvars.media_type}_log_${qvars.camera_id}`, JSON.stringify(theResponse))
                localStorage.setItem(`cameras_${qvars.media_type}_log_${qvars.camera_id}_lastRefresh`, d1.getTime())
                store.state.logs.fetchingList = false
                if (theResponse.Items.length > 0) {
                  theResponse.Items = resortFilterItems(theResponse.Items)
                }
                theResponse.Count = totalItems
                resolve(theResponse)
              })
              .catch(error => {
                store.state.logs.fetchingList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Commands Log Data')
            const theData = JSON.parse(localStorage.getItem(`cameras_${qvars.media_type}_log_${qvars.camera_id}`))
            theData.Items = resortFilterItems(theData.Items)
            theData.Count = totalItems
            console.log(theData)
            store.state.logs.fetchingList = false
            resolve(theData)
          }
        } else {
          reject()
        }
      })
    },
    sendManualCommand(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'camerasmanagement')) {
          console.log('sendManualCommand')
          const apiName = 'APIGateway'
          const path = `/camera/${qvars.camera_id}/command/sender`
          let isJson = true
          try {
            JSON.parse(qvars.command)
          } catch (e) {
            isJson = false
          }
          console.log(isJson)
          if (isJson) {
            API.post(apiName, path, { body: qvars.command })
              .then(response => {
                // console.log(response)
                const theResponse = response
                resolve(theResponse)
              })
              .catch(error => {
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            reject()
          }
        } else {
          reject()
        }
      })
    },
    fetchMediaDownloadItems(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'camerasmanagement') || ability.can('read', 'cameras')) {
          console.log('fetchMediaDownloadItems')
          console.log(qvars)
          const { forceRefresh } = qvars
          store.state.cameraDownloads.fetchingCameraImageDownloadsList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()
          let totalItems = 0

          const resortItems = theItems => {
            console.log(qvars.sortBy)
            // sort the items
            let theSortBy = qvars.sortBy
            let sortDesc1 = qvars.sortDesc
            if (theSortBy === '') {
              theSortBy = 'start_date'
              sortDesc1 = true
            }
            if (sortDesc1) {
              theItems.sort((a, b) => b[theSortBy].localeCompare(a[theSortBy]))
            } else {
              theItems.sort((a, b) => a[theSortBy].localeCompare(b[theSortBy]))
            }
          }
          const paginateItems = theItems2 => {
            const start = (qvars.page - 1) * qvars.perPage
            const end = start + qvars.perPage
            return theItems2.slice(start, end)
          }
          const resortFilterItems = items => {
            const resultItems = []
            // first apply filters
            items.forEach(item => {
              const addThisItem = true
              // if (qvars.cameraType !== null && item.camera_type !== qvars.cameraType) {
              //   addThisItem = false
              // }
              // if (qvars.status !== null && item.status !== qvars.status) {
              //   addThisItem = false
              // }
              // if (qvars.q !== null
              //   && !item.camera_id.toUpperCase().includes(qvars.q.toUpperCase())
              //   && !item.unit_number.includes(qvars.q.toUpperCase())
              //   && !item.imei.toUpperCase().includes(qvars.q.toUpperCase())
              //   && !item.clientname.toUpperCase().includes(qvars.q.toUpperCase())
              //   && !item.camera_type.toUpperCase().includes(qvars.q.toUpperCase())
              //   && !item.controller_bom.toUpperCase().includes(qvars.q.toUpperCase())
              //   && !item.status.toUpperCase().includes(qvars.q.toUpperCase())) {
              //   addThisItem = false
              // }
              if (addThisItem) {
                resultItems.push(item)
              }
            })
            totalItems = resultItems.length
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          if (
            forceRefresh
            || localStorage.getItem('cameras_downloads_images') === null
            || parseInt(localStorage.getItem('cameras_downloads_images_lastRefresh'), 10) + 1440000 < timeDiff // cache for 24 hours
          ) {
            console.log('Refreshing Media Downloads')
            const apiName = 'APIGateway'
            const path = `/camera/${qvars.camera_id}/zip`
            const cameraDataClean = {}
            cameraDataClean.clientname = qvars.clientname

            API.post(apiName, path, { body: cameraDataClean })
              .then(response => {
                console.log('fetchCamerasManagement Response')
                console.log(response)
                const theResponse = {}
                console.log('Store Media Downloads in localstorage')
                localStorage.setItem('cameras_downloads_images', JSON.stringify(response))
                localStorage.setItem('cameras_downloads_images_lastRefresh', d1.getTime())
                theResponse.Items = resortFilterItems(response)
                theResponse.totalItems = totalItems
                console.log(theResponse)
                store.state.cameraDownloads.fetchingCameraImageDownloadsList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.cameraDownloads.fetchingCameraImageDownloadsList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Media Downloads Data')
            const theResponse = {}
            const cameraData = JSON.parse(localStorage.getItem('cameras_downloads_images'))
            theResponse.Items = resortFilterItems(cameraData)
            theResponse.totalItems = totalItems
            console.log(theResponse)
            store.state.cameraDownloads.fetchingCameraImageDownloadsList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    addMediaDownloadItem(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'camerasmanagement') || ability.can('update', 'cameras')) {
          const apiName = 'APIGateway'
          const path = `/camera/${qvars.camera_id}/zip`

          API.put(apiName, path, { body: qvars })
            .then(response => {
              console.log('add Media Download Response')
              console.log(response)
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
  },
}
